import React from 'react';

const Add = () => {
    return (
        <div>
            Add video
        </div>
    );
};

export default Add;