import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware } from 'redux';
import createSagaMiddlware from 'redux-saga';

import apiResponseMiddlware from './apiResponse';
const customMiddleware = [
    apiResponseMiddlware
];

export const sagaMiddleware = createSagaMiddlware();

export const getMiddleware = (history: History) => {
    return applyMiddleware(routerMiddleware(history), ...customMiddleware, sagaMiddleware);
};