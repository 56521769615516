import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import isEqual from 'lodash/isEqual';
import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as app from './reducers';
import { sagas as rootSagas } from './sagas';
import { State } from './types';

import * as pages from './pages';
import { sagaMiddleware, getMiddleware } from '@src/middleware';

const INITIAL_STATE: State = {
    router: {
        action: 'POP',
        location: { hash: '', pathname: '/', search: '', state: undefined }
    },
    app: app.INITIAL_STATE,
    pages: pages.INITIAL_STATE
};

const history = createBrowserHistory();
// patch to avoid stacking same location multiple times in history
const historyPush = history.push;
let lastLocation = history.location;
history.listen(location => (lastLocation = location));
history.push = (path: any, state: object = {}): void => {
    const lastPath =
        lastLocation.pathname + lastLocation.hash + lastLocation.search;
    if (
        lastLocation === null ||
        path !== lastPath ||
        !isEqual(state, lastLocation.state)
    )
        historyPush(path, state);
};

//create reducers
const reducers = combineReducers({
    router: connectRouter(history),
    app: app.reducers,
    pages: pages.reducers
});

const store = createStore(
    reducers,
    composeWithDevTools(getMiddleware(history))
);

sagaMiddleware.run(rootSagas);

export { INITIAL_STATE, history, store };
