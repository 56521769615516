import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Actions, operators } from '@src/redux/actions/sidebar';

export type State = {
  readonly isOpen: boolean;
  readonly activeTab: string;
};

export const INITIAL_STATE: State = {
  isOpen: true,
  activeTab: 'providers'
};

export const reducers = reducerWithInitialState(INITIAL_STATE)
  .case(operators.toggleSidebar, (state): State => ({ ...state, isOpen: !state.isOpen }))
  .caseWithAction(operators.setActiveTab, (state, { payload: { tab } }: Actions.SetActiveTab): State => ({ ...state, activeTab: tab }))