import { all, takeLatest } from 'redux-saga/effects';
import { operators as appOperators, Actions } from '@src/redux/actions/sidebar';
import * as authSagas from './auth';
import * as pages from '../pages';

export function* sagas() {
    yield all([
        takeLatest(appOperators.setActiveTab.type, setActiveTab),
        authSagas.sagas(),
        pages.sagas()
    ]);
}

function* setActiveTab({ payload: { tab } }: Actions.SetActiveTab) {
    console.log('sagas: setActiveTab:', tab);
    yield 1;
}