import { Action, actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('@@photos');

declare namespace Payloads {
    type FetchSearchByAlbumId = { albumName: string };
    type FetchSearchByAlbumIdSuccess = { data: any };
    type FetchSearchByAlbumIdError = { error: object};
}

export declare namespace Actions {
    type FetchSearchByAlbumId = Action<Payloads.FetchSearchByAlbumId>;
    type FetchSearchByAlbumIdSuccess = Action<Payloads.FetchSearchByAlbumIdSuccess>;
    type FetchSearchByAlbumIdError = Action<Payloads.FetchSearchByAlbumIdError>;
}

export const operators = {
    fetchSearchByAlbumId: actionCreator<Payloads.FetchSearchByAlbumId>('FETCH_PHOTOS_BY_ALBUM'),
    fetchSearchByAlbumIdError: actionCreator<Payloads.FetchSearchByAlbumIdError>('FETCH_PHOTOS_BY_ALBUM_ERROR'),
    fetchSearchByAlbumIdSuccess: actionCreator<Payloads.FetchSearchByAlbumIdSuccess>('FETCH_PHOTOS_BY_ALBUM_SUCCESS')
}