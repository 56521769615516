import { combineReducers } from 'redux';

import * as sidebar from './sidebarReducers';

export type State = {
    sidebar: sidebar.State
};

export const INITIAL_STATE: State = {
    sidebar: sidebar.INITIAL_STATE
};

export const reducers = combineReducers({
    sidebar: sidebar.reducers
});