import { Middleware } from "redux";

const middleware: Middleware = store => next => action => {
    const [actionGroup, actionName] = action.type.split(/\/(?=[^\/]+$)/);

    // console.log('actionGroup',actionGroup)
    // console.log('actionName',actionName)

    next(action);
};

export default middleware;