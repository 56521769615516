import React from "react";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import Authentication from '@src/lib/helpers/Authentication';
import { useState } from "react";
const Auth = new Authentication();

const SignIn = (props: any) => {

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const postLogin = (event: any) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    Auth.login(email, password).then(res => {
      props.history.push('/');
    }).catch(err => {
      setError(true);
      setErrorMessage(err.message);
    });
  }

  return (
    <>
      {
        (!Auth.loggedIn()) ?
          (
            <Card>
              <CardBody>
                {(error) ? <span style={{ color: 'red' }}>{errorMessage}</span> : null}
                <div className="m-sm-4">
                  <Form onSubmit={postLogin}>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        bsSize="lg"
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label>Password</Label>
                      <Input
                        bsSize="lg"
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                      />
                      {/* <small>
                <Link to="/auth/reset-password">Forgot password?</Link>
              </small> */}
                    </FormGroup>
                    {/* <div>
              <CustomInput
                type="checkbox"
                id="rememberMe"
                label="Remember me next time"
                defaultChecked
              />
            </div> */}
                    <div className="text-center mt-3">
                      <Button type="submit" color="primary" size="lg">
                        Sign in
                </Button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          ) : props.history.push('/')
      }
    </>
  );
};

export default SignIn;
