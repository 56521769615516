import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlbumObject } from "@src/class/Album";
import Loader from "@src/components/Loader";
import { operators } from '@src/redux/pages/album';
import { State } from "@src/redux/types";
import dayjs from 'dayjs';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { MoreHorizontal } from "react-feather";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { Dispatch } from "redux";
import unsplash3 from "../../assets/img/photos/unsplash-3.jpg";
import AlbumModal from "./Dialog";

export type ModalAction = 'createAlbum' | 'removeAlbum' | 'renameAlbum';
type StateProps = {
    loading: boolean;
    albumList: AlbumObject[] | null;
};

type DispatchProps = {
    onFetchAlbums(): void;
};

type ComponentProps = StateProps & DispatchProps;

const Album = ({ name, description, image, createdAt, options }: any) => (
    <Card>
        {/* <CardImg top src={image} alt="Card image cap" /> */}
        <CardHeader className="px-4 pt-4">
            {
                options.length > 0 ?
                    <div className="card-actions float-right">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a">
                                <MoreHorizontal />
                            </DropdownToggle>
                            <DropdownMenu right>
                                {options}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div> : null
            }
            <CardTitle tag="h5" className="mb-0">
                {name}
            </CardTitle>
        </CardHeader>
        <CardBody className="px-4 pt-2">
            {/* <p>{description}</p> */}
            <CardText>
                <small className="text-muted">{dayjs(createdAt).format('YYYY-MM-DD')}</small>
                <NavLink to={`/albums/view/${name}`}>
                    <Button color="primary" className="float-right mt-n1">
                        View
    </Button>
                </NavLink>
            </CardText>
        </CardBody>
    </Card>
);

const Default = ({ loading, albumList, onFetchAlbums }: ComponentProps) => {

    let modalSelectedAlbum: AlbumObject | null = null;
    const [dialogAction, setDialogAction] = useState<ModalAction | null>(null);

    const onDialogClose = () => {
        setDialogAction(null);
    };

    const getAlbumOptions = (album: AlbumObject) => {
        const albumOptions = [];
        modalSelectedAlbum = album;
        albumOptions.push(
            <DropdownItem key="renameAlbum" onClick={() => setDialogAction('renameAlbum')}>Rename album</DropdownItem>,
            <DropdownItem key="removeAlbum" onClick={() => setDialogAction('removeAlbum')}>Remove album</DropdownItem>
        );

        return albumOptions;
    }

    useEffect(() => {
        onFetchAlbums();
    }, []);

    if (loading) return <Loader />;
    return (
        <Container fluid className="p-0">
            <Button color="primary" className="float-right mt-n1" onClick={() => setDialogAction('createAlbum')}>
                <FontAwesomeIcon icon={faPlus} /> New album
            </Button>
            <h1 className="h3 mb-3">Albums</h1>
            <Row>
                {
                    map(albumList, (album: AlbumObject, index: number) => {
                        return (
                            <Col md="6" lg="3" key={`album_${album.name}`}>
                                <Album
                                    name={album.name}
                                    image={unsplash3}
                                    createdAt={album.inserted_at}
                                    options={getAlbumOptions(album)}
                                />
                            </Col>
                        );
                    })
                }
            </Row>
            {dialogAction ?
                <AlbumModal
                    album={modalSelectedAlbum}
                    action={dialogAction}
                    onClose={onDialogClose} />
                : null}
        </Container>
    );
};

const mapStateToProps = ({ pages: { albums: { loading, albumList } } }: State): StateProps => ({
    loading,
    albumList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onFetchAlbums: () => dispatch(operators.fetchSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(Default);