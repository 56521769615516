import async from '../components/Async';

import {
    Folder as FolderIcon,
    Users as UsersIcon,
    Camera as CameraIcon,
    Video as VideoIcon,
    Disc as DiscVideo
    // BookOpen as BookOpenIcon,
    // CheckSquare as CheckSquareIcon,
    // Grid as GridIcon,
    // Heart as HeartIcon,
    // Layout as LayoutIcon,
    // List as ListIcon,
    // MapPin as MapPinIcon,
    // Monitor as MonitorIcon,
    // PieChart as PieChartIcon,
    // Sliders as SlidersIcon,
    // Calendar as CalendarIcon,
} from "react-feather";

// Auth
import SignIn from '@src/pages/auth/SignIn';

// Albums
const AlbumsDefault = async(() => import('@src/pages/album/Default'));
const AlbumsAdd = async(() => import('@src/pages/album/Add'));
const AlbumsEdit = async(() => import('@src/pages/album/Edit'));
const AlbumsView = async(() => import('@src/pages/album/View'));

// Videos
const VideoDefault = async(() => import('@src/pages/videos/Default'));
const VideoAdd = async(() => import('@src/pages/videos/Add'));
const VideoEdit = async(() => import('@src/pages/videos/Edit'));
const VideoView = async(() => import('@src/pages/videos/View'));

// Photos
const PhotoDefault = async(() => import('@src/pages/photos/Default'));
const PhotoAdd = async(() => import('@src/pages/photos/Add'));
const PhotoEdit = async(() => import('@src/pages/photos/Edit'));
const PhotoView = async(() => import('@src/pages/photos/View'));

// Documents
const DocumentsDefault = async(() => import('@src/pages/documents/Default'));

const albumRoutes = {
    path: '/albums',
    name: 'Albums',
    icon: DiscVideo,
    children: [
        {
            path: '/albums',
            name: 'List',
            inSidebar: true,
            component: AlbumsDefault
        },
        {
            path: '/albums/add',
            name: 'Add',
            inSidebar: true,
            component: AlbumsAdd
        },
        {
            path: '/albums/edit/:album',
            name: 'Edit',
            inSidebar: false,
            component: AlbumsEdit
        },
        {
            path: '/albums/view/:album',
            name: 'View',
            inSidebar: false,
            component: AlbumsView
        }
    ]
}

const photosRoutes = {
    path: '/fotos',
    name: 'Fotos',
    icon: CameraIcon,
    children: [
        {
            path: '/fotos',
            name: 'List',
            inSidebar: true,
            component: PhotoDefault
        },
        {
            path: '/fotos/add',
            name: 'Add',
            inSidebar: true,
            component: PhotoAdd
        },
        {
            path: '/fotos/edit/:photo',
            name: 'Edit',
            inSidebar: false,
            component: PhotoEdit
        },
        {
            path: '/fotos/view/:photo',
            name: 'View',
            inSidebar: false,
            component: PhotoView
        }
    ]
}

const videoRoutes = {
    path: '/videos',
    name: 'Videos',
    icon: VideoIcon,
    children: [
        {
            path: '/videos',
            name: 'List',
            inSidebar: true,
            component: VideoDefault
        },
        {
            path: '/videos/add',
            name: 'Add',
            inSidebar: true,
            component: VideoAdd
        },
        {
            path: '/videos/edit/:video',
            name: 'Edit',
            inSidebar: false,
            component: VideoEdit
        },
        {
            path: '/videos/view/:video',
            name: 'View',
            inSidebar: false,
            component: VideoView
        }
    ]
}

const documentRoutes = {
    path: '/documents',
    name: 'Documents',
    icon: FolderIcon,
    children: [
        {
            path: '/documents',
            name: 'Documents',
            inSidebar: true,
            component: DocumentsDefault
        }
    ]
}

const authRoutes = {
    path: '/auth',
    name: 'Auth',
    icon: UsersIcon,
    children: [
        {
            path: '/auth/sign-in',
            name: 'Sign in',
            inSidebar: false,
            component: SignIn
        }
    ]
}

// Dashboard specific routes
export const dashboard = [
    albumRoutes,
    photosRoutes,
    videoRoutes,
    documentRoutes
];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
    albumRoutes,
    photosRoutes,
    videoRoutes,
    documentRoutes
];