import React from 'react';
import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';

const Dialog = ({ id, title, body, onCancel, onAccept }: any) => (
    <>
        <Modal
            autoFocus={false}
            isOpen={onCancel ? true : false}
            toggle={onCancel}
            centered
        >
            <ModalHeader toggle={onCancel}>
                {title}
            </ModalHeader>
            <ModalBody className="text-center m-3">
                {body}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onCancel}>
                    Close
                  </Button>{" "}
                <Button
                    color='success'
                    onClick={onAccept}
                >
                    Save changes
                  </Button>
            </ModalFooter>
        </Modal>
    </>
);

export default Dialog;