import Authentication from '@src/lib/helpers/Authentication';
import { operators } from '@src/redux/actions/auth';
import { push } from 'connected-react-router';
import { all, put, takeLatest } from "redux-saga/effects";

const authentication = new Authentication();

export function* sagas() {
    yield all([
        takeLatest(operators.removeSession.type, removeSession)
    ]);
};

function* removeSession() {
    yield authentication.logout();
    yield put(push({ pathname: '/sign-in' }));
}