import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { Actions, operators } from './actions';
import Api from "@src/lib/ajax/Api";
import { State } from '@src/redux/types';
import { cloneDeep, sortBy } from "lodash";
import { AlbumObject } from "../../../class/Album";
import { routerActions } from 'connected-react-router';

const api = new Api('');
export function* sagas() {
    yield all([
        takeLatest(operators.fetchSearch.type, fetchSearch),
        takeLatest(operators.createAlbum.type, createAlbum)
    ]);
};

// rename to fetchAlbumList and error/success too
function* fetchSearch({ }: Actions.FetchSearch) {
    try {
        const albumList: AlbumObject[] = yield call([api, 'get'], '/albums');
        yield put(operators.fetchSearchSuccess({ albumList }));
    } catch (error) {
        yield put(operators.fetchSearchError({ error }));
    };
};

function* createAlbum({ payload: { name } }: Actions.CreateAlbum) {
    let albumList: AlbumObject[] = yield select((state: State) => state.pages.albums.albumList);

    try {
        const album: AlbumObject = yield call([api, 'post'], '/albums', { data: { name } });
        console.log(album)
        albumList = sortBy(cloneDeep([...albumList, album]), albumItem => albumItem.inserted_at);
        yield put(operators.createAlbumSuccess({ albumList }));
        yield put(routerActions.push(`/albums/view/${album.name}`));
    } catch (error) {
        yield put(operators.createAlbumError({ error }));
    }
}