import { Action, actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('@@Sidebar');

declare namespace Payloads {
    type ToggleSidebar = void;
    type SetActiveTab = { tab: string };
};

export declare namespace Actions {
    type ToggleSidebar = Action<Payloads.ToggleSidebar>;
    type SetActiveTab = Action<Payloads.SetActiveTab>;
};

export const operators = {
    toggleSidebar: actionCreator<Payloads.ToggleSidebar>('SIDEBAR_VISIBILITY_TOGGLE'),
    setActiveTab: actionCreator<Payloads.SetActiveTab>('SET_ACTIVE_TAB')
};