import React from 'react';

const View = () => {
    return (
        <div>
            View video
        </div>
    );
};

export default View;