import React from 'react';
import { Container, Card, CardImg, Row, Col } from 'reactstrap';
import unsplash3 from "@src/assets/img/photos/unsplash-3.jpg";

const Default = () => {
    return (
        <Container fluid className="p-0">
            <Row>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>

                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
                <Col md="6" lg="3">
                    <Card>
                        <CardImg className="card-img-rounded" src={unsplash3} alt="Card image cap" />
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Default;