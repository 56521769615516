import React from 'react';
import { Provider } from 'react-redux';

import { store, history } from './redux';
import Routes from './routes';

import './assets/scss/classic.scss';
import { ConnectedRouter } from 'connected-react-router';

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </Provider>
);

export default App;