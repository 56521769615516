import { combineReducers} from 'redux';

import * as albums from './album';
import * as photos from './photos';

export type State = {
    albums: albums.State;
    photos: photos.State;
};

export const INITIAL_STATE: State = {
    albums: albums.INITIAL_STATE,
    photos: photos.INITIAL_STATE,
};

export const reducers = combineReducers({
    albums: albums.reducers,
    photos: photos.reducers
});