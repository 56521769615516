import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link, NavLink } from 'react-router-dom';
import routes from '../routes/configRoutes';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Collapse } from 'reactstrap';

import { State } from '@src/redux/types';

const SidebarItem = withRouter(
    ({ name, to, location }: any) => {
        const getSidebarItemClass = (path: string) => {
            return location.pathname === path ? "active" : "";
        };

        return (
            <li className={"sidebar-item " + getSidebarItemClass(to)}>
                <NavLink to={to} className="sidebar-link" activeClassName="active">
                    {name}
                </NavLink>
            </li>
        );
    }
);

const SidebarCategory = withRouter(
    ({ name, to, icon: Icon, isOpen, onClick, children, location }: any) => {
        const getSidebarItemClass = (path: string) => {
            return location.pathname.indexOf(path) !== -1 ||
                (location.pathname === "/" && path === "/albums") ? "active" : "";
        };

        return (
            <li className={"sidebar-item " + getSidebarItemClass(to)}>
                <span
                    data-toggle="collapse"
                    className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
                    onClick={onClick}
                    aria-expanded={isOpen ? "true" : "false"}>
                    <Icon size={18} className="align-middle mr-3" />
                    <span className="align-middle">{name}</span>
                </span>
                <Collapse isOpen={isOpen}>
                    <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                        {children}
                    </ul>
                </Collapse>
            </li>
        );
    }
);

class Sidebar extends Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {};
    }

    toggle = (index: number) => {
        Object.keys(this.state).forEach(
            item =>
                this.state[index] || this.setState(() => ({ [item]: false }))
        )

        this.setState((state: any) => ({ [index]: !state[index] }))
    }

    componentDidMount() {
        /* Open collapse element that matches current url */
        const pathName = this.props.location.pathname;

        routes.forEach((route: any, index: number) => {
            const isActive = pathName.indexOf(route.path) === 0;
            const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/" && pathName === "/albums" ? true : false;

            this.setState(() => ({
                [index]: isActive || isOpen || isHome
            }));
        });
    }

    render() {
        const { isOpen } = this.props;
        return (
            <nav className={"sidebar" + (!isOpen ? " toggled" : "") + " sidebar-sticky"}>
                <div className="sidebar-content">
                    <PerfectScrollbar>
                        <Link to="/" className="sidebar-brand">
                            Galeria
                        </Link>

                        <ul className="sidebar-nav">
                            {
                                routes.map((category: any, index: number) => {
                                    return (
                                        <Fragment key={index}>
                                            {category.header ? (
                                                <li className="sidebar-header">{category.header}</li>
                                            ) : null}

                                            {category.children ? (
                                                <SidebarCategory
                                                    name={category.name}
                                                    to={category.path}
                                                    icon={category.icon}
                                                    isOpen={this.state[index]}
                                                    onClick={() => this.toggle(index)}
                                                >
                                                    {category.children.map((route: any, index: number) => (
                                                        (route.inSidebar) ? (
                                                            <SidebarItem
                                                                key={index}
                                                                name={route.name}
                                                                to={route.path} />) : null
                                                    ))}
                                                </SidebarCategory>
                                            ) : (
                                                    <SidebarItem
                                                        name={category.name}
                                                        to={category.path}
                                                    />
                                                )}
                                        </Fragment>
                                    );
                                })
                            }
                        </ul>
                    </PerfectScrollbar>
                </div>
            </nav >
        );
    }
}

type StateProps = {
    isOpen: boolean;
};

const mapStateToProps = ({app: { sidebar: { isOpen }}}: State): StateProps => ({
    isOpen
});

export default withRouter(connect(mapStateToProps, null)(Sidebar))

// export default withRouter(
//     connect((store: any) => ({
//         sidebar: store.sidebar
//     }))(Sidebar)
// );