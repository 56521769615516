import { Dispatch } from "redux";
import { connect } from "react-redux";

import { operators } from "@src/redux/pages/album";
import { StateProps, DispatchProps } from "./types";
import AlbumModal from "./AlbumModal";

const mapStateToProps = (): StateProps => ({});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onCreateAlbum: name => dispatch(operators.createAlbum({ name }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AlbumModal);