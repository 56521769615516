import { Action, actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('@@auth');

declare namespace Payloads {
    type RemoveSession = void;
};

export declare namespace Actions {
    type RemoveSession = Action<Payloads.RemoveSession>;
};

export const operators = {
    removeSession: actionCreator<Payloads.RemoveSession>('REMOVE_SESSION')
};