import axios, { AxiosInstance } from 'axios';
export const API_BASE_URL = process.env.REACT_APP_API_URL;

const serverConfig = {
  baseURL: `${API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
};

type Method = 'get' | 'post';

export default class Api {
  private _ajax: AxiosInstance;

  constructor(namespace?: string) {
    let config = { ...serverConfig };
    config.baseURL += `/${namespace}`;
    this._ajax = axios.create(config);
  }

  private async _request(method: Method, url: string, config: object = {}): Promise<any> {

    const request: { [index: string]: any } = { headers: {}, ...config, method, url };
    request.headers.Authorization = 'Bearer ' + Api.token();

    try {
      const response = await this._ajax.request(request);
      return response.data.response;
    } catch (err) {
      const error = err.response.data.error;
      if (!error) throw { code: 'API_DOWN', message: 'Api is down' };
      if (!error.code) throw { code: 'API_UNKNOWN_ERROR', message: 'Api unknown error' };
      if (error.code === '')
      throw error;
    }
  }

  public async get(url: string, config?: object) {
    return this._request('get', url, config);
  }

  public async post(url: string, config?: object) {
    return this._request('post', url, config);
  }

  private static token() {
    return localStorage.getItem('token');
  }
}