import decode from 'jwt-decode';
import Api from '@src/lib/ajax/Api';

const API = new Api('');
class AuthService {

    login = (email: string, password: string): Promise<any> => {
        return API.post('/login', { data: { email, password } }).then((response: any) => {
            this.setToken(response.token);
            this.setProfile(response.user)
            return Promise.resolve(response);
        }, err => {
            return Promise.reject(err);
        });
    }

    loggedIn = () => {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    isTokenExpired = (token: string) => {
        try {
            const decoded: any = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    setToken = (idToken: string) => {
        localStorage.setItem('token', idToken);
    }

    setProfile = (profile: object) => {
        localStorage.setItem('profile', JSON.stringify(profile));
    }

    getToken = () => {
        return localStorage.getItem('token');
    }

    logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
    }

    getProfile = () => {
        const profile: string = localStorage.getItem('profile') || '';
        return JSON.parse(profile);
    }
}

export default AuthService;