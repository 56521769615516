import React from 'react';
import Content from '@src/components/Content';
import Main from '@src/components/Main';
import Navbar from '@src/components/Navbar';
import Sidebar from '@src/components/Sidebar';
import Wrapper from '@src/components/Wrapper';

const Dashboard = ({ children }: any) => (
        <>
            <Wrapper>
                <Sidebar />
                <Main>
                    <Navbar />
                    <Content>{children}</Content>
                </Main>
            </Wrapper>
        </>
);

export default Dashboard;