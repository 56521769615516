import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Actions, operators } from '@src/redux/pages/album/actions';
import { AlbumObject } from '../../../class/Album';

export type State = {
    readonly loading: boolean;
    readonly albumList: AlbumObject[] | null;
}

export const INITIAL_STATE: State = {
    loading: false,
    albumList: null
};

export const reducers = reducerWithInitialState(INITIAL_STATE)
    .caseWithAction(operators.fetchSearch, (state, {}: Actions.FetchSearch): State => ({ ...state, loading: true }))
    .caseWithAction(operators.fetchSearchSuccess, (state, { payload: { albumList } }: Actions.FetchSearchSuccess): State => ({ ...state, albumList, loading: false }))
    .caseWithAction(operators.fetchSearchError, (state, { }: Actions.FetchSearchError): State => ({ ...state, loading: false }))
    .caseWithAction(operators.createAlbum, (state, {}: Actions.CreateAlbum): State => ({ ...state, loading: true}))
    .caseWithAction(operators.createAlbumSuccess, (state, { payload: { albumList }}:Actions.CreateAlbumSuccess) => ({ ...state, albumList, loading: false}))
    .caseWithAction(operators.createAlbumError, (state, {}: Actions.CreateAlbumError): State => ({ ...state, loading: false}))