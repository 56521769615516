import avatar1 from "@src/assets/img/avatars/avatar.jpg";
import { operators as authOperators } from '@src/redux/actions/auth';
import { operators as sidebarOperators } from '@src/redux/actions/sidebar';
import React from 'react';
import { Settings, User } from "react-feather";
import { connect } from 'react-redux';
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, UncontrolledDropdown } from 'reactstrap';
import { Dispatch } from 'redux';
import Authentication from '../lib/helpers/Authentication';

type DispatchProps = {
    onRemoveSession(): void;
    onToggleSidebar(): void;
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onRemoveSession: () => dispatch(authOperators.removeSession()),
    onToggleSidebar: () => dispatch(sidebarOperators.toggleSidebar())
})

const NavbarComponent = ({ onRemoveSession, onToggleSidebar }: DispatchProps) => {

    const Auth = new Authentication();
    const profile: any = Auth.getProfile();
    return (
        <Navbar color="white" light expand>
            <span
                className="sidebar-toggle d-flex mr-2"
                onClick={() => { onToggleSidebar() }}
            >
                <i className="hamburger align-self-center" />
            </span>

            <Collapse navbar>
                <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <span className="d-inline-block d-sm-none">
                            <DropdownToggle nav caret>
                                <Settings size={18} className="align-middle" />
                            </DropdownToggle>
                        </span>
                        <span className="d-none d-sm-inline-block">
                            <DropdownToggle nav caret>
                                <img
                                    src={avatar1}
                                    className="avatar img-fluid rounded-circle mr-1"
                                    alt="Chris Wood"
                                />
                                <span className="text-dark">{`${profile.name} ${profile.surname}`}</span>
                            </DropdownToggle>
                        </span>
                        <DropdownMenu right>
                            <DropdownItem>
                                <User size={18} className="align-middle mr-2" />
                                Profile
              </DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem onClick={() => onRemoveSession()}>Sign out</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default connect(null, mapDispatchToProps)(NavbarComponent);