import { Actions, operators } from './actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

export type State = {
    readonly loading: boolean;
    readonly data: any;
};

export const INITIAL_STATE: State = {
    loading: false,
    data: null
};

export const reducers = reducerWithInitialState(INITIAL_STATE)
    .caseWithAction(operators.fetchSearchByAlbumId, (state, { }: Actions.FetchSearchByAlbumId): State => ({ ...state, loading: true }))
    .caseWithAction(operators.fetchSearchByAlbumIdSuccess, (state, { payload: { data } }: Actions.FetchSearchByAlbumIdSuccess): State => ({ ...state, data, loading: false }))
    .caseWithAction(operators.fetchSearchByAlbumIdError, (state, { }: Actions.FetchSearchByAlbumIdError): State => ({ ...state, loading: false }))