import AuthLayout from '@src/layouts/Auth';
import DashboardLayout from '@src/layouts/Dashboard';
import AuthService from '@src/lib/helpers/Authentication';
import AlbumDefault from '@src/pages/album/Default';
import AlbumsEdit from '@src/pages/album/Edit';
import AlbumsView from '@src/pages/album/View';
import SignIn from '@src/pages/auth/SignIn';
import DocumentsDefault from '@src/pages/documents/Default';
import PhotoAdd from '@src/pages/photos/Add';
import PhotoDefault from '@src/pages/photos/Default';
import PhotoEdit from '@src/pages/photos/Edit';
import PhotoView from '@src/pages/photos/View';
import VideoAdd from '@src/pages/videos/Add';
import VideoDefault from '@src/pages/videos/Default';
import VideoEdit from '@src/pages/videos/Edit';
import VideoView from '@src/pages/videos/View';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const Authentication = new AuthService();

const AuthenticatedRoute = ({ component: Component, layout: Layout, ...rest }: any) =>
    <Route
        {...rest}
        render={props =>{
            return Authentication.loggedIn() ? (
                <Layout>
                    <Component {...props} />
                </Layout>
            ) : (
                    <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />
                )
        }}
    />;

const Routes = () => (
    <Switch>
        <Route exact path='/sign-in' render={props => (<AuthLayout><SignIn {...props} /></AuthLayout>)} />

        <AuthenticatedRoute exact path='/albums/edit/:album' component={AlbumsEdit} layout={DashboardLayout} />
        <AuthenticatedRoute exact path='/albums/view/:album' component={AlbumsView} layout={DashboardLayout} />
        <AuthenticatedRoute exact path='/albums' component={AlbumDefault} layout={DashboardLayout} />

        <AuthenticatedRoute exact path='/videos' component={VideoDefault} layout={DashboardLayout} />
        <AuthenticatedRoute exact path='/videos/add' component={VideoAdd} layout={DashboardLayout} />
        <AuthenticatedRoute exact path='/videos/edit/:video' component={VideoEdit} layout={DashboardLayout} />
        <AuthenticatedRoute exact path='/videos/view/:video' component={VideoView} layout={DashboardLayout} />

        <AuthenticatedRoute exact path='/fotos' component={PhotoDefault} layout={DashboardLayout} />
        <AuthenticatedRoute exact path='/fotos/add' component={PhotoAdd} layout={DashboardLayout} />
        <AuthenticatedRoute exact path='/fotos/edit/:photo' component={PhotoEdit} layout={DashboardLayout} />
        <AuthenticatedRoute exact path='/fotos/view/:photo' component={PhotoView} layout={DashboardLayout} />

        <AuthenticatedRoute exact path='/documents' component={DocumentsDefault} layout={DashboardLayout} />

        <Redirect to='/albums' from='/' />
    </Switch>
);

export default Routes;