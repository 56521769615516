import React from 'react';
import { Input, InputGroup } from 'reactstrap';
import Dialog from '../../../components/Dialog';
import { ModalAction } from '../Default';
import { ComponentProps } from './types';

type ModalInfo = {
    title: string,
    body: string
};

const AlbumModal = (props: ComponentProps) => {
    const input: React.RefObject<any> = React.createRef();
    const { action, album } = props;
    const { onCreateAlbum, onClose } = props;
    const dialogInputValue: string = "";

    const onModalAccepted = () => {
        switch (action) {
            case 'renameAlbum':
                alert(action);
                // onCreateAlbum();
                break;
            case 'removeAlbum':
                alert(action);
                break;
            case 'createAlbum':
                // alert(action);
                const name: string = input.current.value;
                onCreateAlbum(name);
                break;
        }

        onClose();
    }

    const onModaldCancelled = () => {
        onClose();
    }

    const modalInfo: { [key: string]: any } = {
        renameAlbum: {
            title: 'Rename album',
            body: <InputGroup>
                <Input type="text" /*value={album?.name}*/ />
            </InputGroup>
        },
        removeAlbum: {
            title: 'Remove album',
            body: 'form to delete album'
        },
        createAlbum: {
            title: 'Create album',
            body: <InputGroup>
            <Input autoFocus type="text" innerRef={input} placeholder="Album name" />
            </InputGroup>
        }
    };

    const info: ModalInfo = modalInfo[action as ModalAction];
    
    return (
        <Dialog
            id='albumListModal'
            title={info.title}
            body={info.body}
            onAccept={onModalAccepted}
            onCancel={onModaldCancelled}
        />
    );
}

export default AlbumModal;