import { Action, actionCreatorFactory } from 'typescript-fsa';
import { AlbumObject } from '../../../class/Album';

const actionCreator = actionCreatorFactory('@@albums');

declare namespace Payloads {
    type FetchSearch = void;
    type FetchSearchError = { error: object};
    type FetchSearchSuccess = { albumList: AlbumObject[] };
    type CreateAlbum = { name: string};
    type CreateAlbumSuccess = { albumList: AlbumObject[]};
    type CreateAlbumError = { error: object};
};

export declare namespace Actions {
    type FetchSearch = Action<Payloads.FetchSearch>;
    type FetchSearchError = Action<Payloads.FetchSearchError>;
    type FetchSearchSuccess = Action<Payloads.FetchSearchSuccess>;
    type CreateAlbum = Action<Payloads.CreateAlbum>;
    type CreateAlbumSuccess = Action<Payloads.CreateAlbumSuccess>;
    type CreateAlbumError = Action<Payloads.CreateAlbumError>;
};

export const operators = {
    fetchSearch: actionCreator<Payloads.FetchSearch>('FETCH_SEARCH'),
    fetchSearchError: actionCreator<Payloads.FetchSearchError>('FETCH_SEARCH_ERROR'),
    fetchSearchSuccess: actionCreator<Payloads.FetchSearchSuccess>('FETCH_SEARCH_SUCCESS'),
    createAlbum: actionCreator<Payloads.CreateAlbum>('CREATE_ALBUM'),
    createAlbumSuccess: actionCreator<Payloads.CreateAlbumSuccess>('CREATE_ALBUM_SUCCESS'),
    createAlbumError: actionCreator<Payloads.CreateAlbumError>('CREATE_ALBUM_ERROR')
}