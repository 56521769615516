import { all, takeLatest, call, put } from "redux-saga/effects";
import { Actions, operators } from './actions';
import Api from '@src/lib/ajax/Api';

const api = new Api('');

export function* sagas() {
    yield all([
        takeLatest(operators.fetchSearchByAlbumId.type, fetchSearchByAlbumId)
    ]);
}

function* fetchSearchByAlbumId({payload: { albumName }}: Actions.FetchSearchByAlbumId) {
    console.log('fetchSearchByAlbumId albumName:', albumName);

    try {
        const searchResult = yield call([api, 'get'], `/photos/album/${albumName}`);
        console.log('fetchSearchByAlbumId result:',searchResult);
        yield put(operators.fetchSearchByAlbumIdSuccess({ data: searchResult }))
        // console.log(searchResult);
    } catch (error) {
        yield put(operators.fetchSearchByAlbumIdError({ error }));
    }
};