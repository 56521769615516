import React from 'react';

const Default = () => {
    return (
        <div>
            Video list
        </div>
    );
};

export default Default;