import { operators } from '@src/redux/pages/photos';
import { State } from '@src/redux/types';
import dayjs from 'dayjs';
import map from 'lodash/map';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';
import { Card, CardBody, CardImg, CardText, Col, Container, Row } from 'reactstrap';
import { Dispatch } from 'redux';
import Loader from '@src/components/Loader';

type StateProps = {
    loading: boolean;
    photos: any;
}

type DispatchProps = {
    onFetchPhotos(albumId: string): void;
}

type OwnProps = {
    match: match<{ album: string }>
}

type ComponentProps = OwnProps & StateProps & DispatchProps;

const Photo = ({ album, img, insertedAt }: any) => (
    <Card>
        <CardImg top src={`http://album.marterial.es/photos/${album}/${img}`} alt="Card image cap" />
        <CardBody className="px-4 pt-2">
            <CardText>
                <small className="text-muted">{dayjs(insertedAt).format('YYYY-MM-DD')}</small>
            </CardText>
        </CardBody>
    </Card>
);

const View = ({ match, loading, photos, onFetchPhotos }: ComponentProps) => {
    const { params: { album } } = match;

    useEffect(() => {
        onFetchPhotos(album);
    }, []);

    if (loading) return <Loader />
    console.log('view:', photos);
    return (
        <Container fluid className="p-0">
            <Row>
                <Col xs={12}>
                    View album: {album}
                </Col>
            </Row>
            <Row>
                {
                    map(photos, (pho, index) => {
                        // console.log(pho);
                        return (
                            <Col md="6" lg="3" key={`${pho.photo}_${index}`}>
                                <Photo album={album} img={pho.photo} insertedAt={pho.inserted_at} />
                            </Col>
                        );
                    })
                }
            </Row>
        </Container>
    );
};

const mapStateToProps = ({ pages: { photos: { loading, data } } }: State): StateProps => ({
    loading,
    photos: data
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onFetchPhotos: (albumName) => dispatch(operators.fetchSearchByAlbumId({ albumName }))
})

export default connect(mapStateToProps, mapDispatchToProps)(View);